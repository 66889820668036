export enum SmokingPreference {
  NO_SMOKING = 'NO_SMOKING',
  SMOKING_BREAKS_OUTSIDE_ONLY = 'SMOKING_BREAKS_OUTSIDE_ONLY',
  SMOKING_ALLOWED = 'SMOKING_ALLOWED',
}

export const smokingPreferenceOptions = {
  [SmokingPreference.NO_SMOKING]: 'Ingen røyking',
  [SmokingPreference.SMOKING_BREAKS_OUTSIDE_ONLY]: 'Tillater røykepauser',
  [SmokingPreference.SMOKING_ALLOWED]: 'Tillater røyking i bilen',
};
