import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { chattinessLevelOptions } from '../../enums/ChattinessLevel';
import { ReservationStatus, reservationStatusText } from '../../enums/ReservationStatus';
import { smokingPreferenceOptions } from '../../enums/SmokingPreference';
import { Reservation } from '../../models/Reservation';
import { updateReservationStatus } from '../../services/TripService';
import { fetchReservationsOnUser } from '../../services/UserService';
import { formatDateToWeekday, formatTime } from '../../utilities/DateUtils';
import { useAuth } from '../AuthProvider/AuthProvider';
import { CancellationModal } from '../Modals/CancellationModal';
import { Rating } from '../Rating/Rating';
import './upcoming-trips-list.scss';

const renderUpcomingTrips = (reservation: Reservation, openModal: (reservation: Reservation) => void, isModalOpen: boolean, closeModal: () => void, cancelTrip: () => void, openCard: number, index: number, toggleCard: (index: number) => void) => {
  const trip = reservation.trip;
  return <>
    <div className="trip-row">
      <div className="trip-date">{formatDateToWeekday(trip.startDate)}</div>
      <div className="reservation-status">
        <div className={`reservation-status-floating ${reservation.status.toLowerCase()}`}>
          {reservationStatusText[reservation.status]}
        </div>
        {reservation.status == ReservationStatus.PENDING &&
          <button aria-label="cancel" onClick={() => openModal(reservation)}
                  className={`reservation-status-floating cancel clickable`}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        }
        <CancellationModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onConfirm={cancelTrip}
        />
      </div>
    </div>
    <div className="trip-row">
      <div className="trip-col">
        <div className="trip-time">{formatTime(trip.startTime)}</div>
      </div>
      <div className="trip-col">
        <div className="trip-timeline">
          <div className="circle"></div>
          <div className="line"></div>
          <div className="circle"></div>
        </div>
      </div>
      <div className="trip-col">
        <div className="trip-segment">
          <div className="trip-location">
            <div className="trip-location-name">{trip.startPoint.name}</div>
            <div className="trip-location-county">{trip.startPoint.county}</div>
            <>&nbsp;</>
          </div>
        </div>
        <div className="trip-segment">
          <div className="trip-location">
            <div className="trip-location-name">{trip.destination.name}</div>
            <div className="trip-location-county">{trip.destination.county}</div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-expand">
      <div className={`trip-expand ${openCard === index && 'expanded'}`}>
        <Rating trip={reservation.trip} reservation={reservation} />
        <div className="trip-driver-info">
          <div className="trip-driver-info-row">
            <span className="driver-info-label">Alder:</span>
            <span className="driver-info-data">{reservation.trip.driver.age} år</span>
          </div>
          <div className="trip-driver-info-row">
            <span className="driver-info-label">Beskrivelse:</span>
            <span className="driver-info-data">{reservation.trip.driver.driverDetails!.driverBio}</span>
          </div>
          <div className="trip-driver-info-row">
            <span className="driver-info-label">Pratenivå:</span>
            <span
              className="driver-info-data">{chattinessLevelOptions[reservation.trip.driver.driverDetails!.chattinessLevel]}</span>
          </div>
          <div className="trip-driver-info-row">
            <span className="driver-info-label">Røykepreferanse:</span>
            <span
              className="driver-info-data">{smokingPreferenceOptions[reservation.trip.driver.driverDetails!.smokingPreference]}</span>
          </div>
        </div>
        <div className="trip-divider"></div>
        <div className="trip-details-row">
          <div className="trip-text">Kjøretøy:</div>
          <div className="trip-bold">
            {reservation.trip.licensePlateNumber} {reservation.trip.vehicleDetails.color} {reservation.trip.vehicleDetails.brand}
          </div>
        </div>
        <div className="trip-divider"></div>
        <div className="trip-details-row">
          <div className="trip-text">Totalpris for 1 passasjer:</div>
          <div className="trip-bold">Kr. {reservation.trip.reservationPrice},-</div>
        </div>
      </div>
    </div>
    <div className="trip-row">
      <button aria-label="info" className="trip-expand-btn" onClick={() => toggleCard(index)}>
        <i className={`trip-arrow ${openCard === index ? 'up' : 'down'}`}></i>
      </button>
    </div>
  </>;
};


export const UpcomingTripsList = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [openCard, setOpenCard] = useState<number>(-1);
  const [error, setError] = useState<string>();
  const [reservation, setReservation] = useState<Reservation>();
  const [reservations, setReservations] = useState<Reservation[]>();
  const { user, token } = useAuth();

  const loadReservations = async () => {
    try {
      const reservationData = await fetchReservationsOnUser(token, user);
      setReservations(reservationData);
      setError('');
    } catch (error) {
      setError('Klarte ikke å hente reservasjoner');
      console.error(error);
    }
  };

  useEffect(() => {
    if (user && token) {
      loadReservations();
    }
  }, [user]);


  const toggleCard = (index: number) => {
    openCard == index
      ? setOpenCard(-1)
      : setOpenCard(index);
  };


  const openModal = (reservation: Reservation): void => {
    setIsModalOpen(true);
    setReservation(reservation);
  };
  const closeModal = (): void => {
    setIsModalOpen(false);
    setReservation(undefined);
  };

  const cancelTrip = (): void => {
    const performCancelTrip = async () => {
      if (reservation) {
        try {
          await updateReservationStatus(ReservationStatus.CANCELED, reservation.id, reservation.trip.id, token);
          loadReservations();
          setError('');
        } catch (error) {
          setError('Klarte ikke å kansellere reisen');
          console.error(error);
        }
      }
    };
    performCancelTrip();
    closeModal();
  };

  return (
    <div className="trip-list">
      {error && <div className="container-center error-message">{error}</div>}
      {reservations
        && (reservations.length === 0 ?
          <h3>Du har ingen kommende reiser. Du kan søke etter nye reiser <Link to="/search">
            her
          </Link>.</h3>
          : reservations.map((reservation: Reservation, index: number) => (
            <div
              key={index}
              className="trip-card"
            >
              {renderUpcomingTrips(reservation, openModal, isModalOpen, closeModal, cancelTrip, openCard, index, toggleCard)}
            </div>
          )))}
    </div>
  );
};
