import React from 'react';
import {
  ChattinessLevel,
  chattinessLevelOptions,
} from '../../enums/ChattinessLevel';
import {
  SmokingPreference,
  smokingPreferenceOptions,
} from '../../enums/SmokingPreference';
import { DriverDetails } from '../../models/User';
import { formatDateToReadableDateWithYear } from '../../utilities/DateUtils';
import './driver-info.scss';

export const DriverInfo = ({ driverDetails }: {
  driverDetails: DriverDetails;
}) => {
  const chattinessLevelFA = {
    [ChattinessLevel.NOT_CHATTY]: 'fa-volume-xmark',
    [ChattinessLevel.BALANCED]: 'fa-volume-low',
    [ChattinessLevel.CHATTY]: 'fa-volume-high',
  };

  const smokingPreferenceFA = {
    [SmokingPreference.NO_SMOKING]: 'fa-ban-smoking',
    [SmokingPreference.SMOKING_BREAKS_OUTSIDE_ONLY]: 'fa-smoking',
    [SmokingPreference.SMOKING_ALLOWED]: 'fa-smoking',
  };

  return (<div className="container-center">
      <h1 className="fremtur-heading">Sjåførinformasjon</h1>
      <div className="driver-info-card">
        <div className="driver-info-row">
          <i className="fa fa-circle-info driver-info-icon"></i>
          <span className="driver-info-label">Sjåførbeskrivelse:</span>
          <span className="driver-info-data">{driverDetails.driverBio}</span>
        </div>
        <div className="driver-info-row">
          <i className="fa fa-star-half-stroke driver-info-icon"></i>
          <span className="driver-info-label">Vurdering:</span>
          <span className="driver-info-data">
          {driverDetails.rating !== 0
            ? `${driverDetails.rating} / 5 ★`
            : 'Du har ikke blitt vurdert'}
        </span>
        </div>
        <div className="driver-info-row">
          <i className="fa fa-id-card driver-info-icon"></i>
          <span className="driver-info-label">Førerkort siden:</span>
          <span className="driver-info-data">
          {formatDateToReadableDateWithYear(new Date(driverDetails.driverLicenceSince))}
        </span>
        </div>
        <div className="driver-info-row">
          <i className={`fa ${chattinessLevelFA[driverDetails.chattinessLevel]} driver-info-icon`}></i>
          <span className="driver-info-label">Pratenivå:</span>
          <span className="driver-info-data">{chattinessLevelOptions[driverDetails.chattinessLevel]}</span>
        </div>
        <div className="driver-info-row">
          <i className={`fa ${smokingPreferenceFA[driverDetails.smokingPreference]} driver-info-icon`}></i>
          <span className="driver-info-label">Røykepreferanse:</span>
          <span className="driver-info-data">{smokingPreferenceOptions[driverDetails.smokingPreference]}</span>
        </div>
      </div>
    </div>
  );
};