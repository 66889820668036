import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { nb } from 'date-fns/locale/nb';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useFetchVehicles } from '../../hooks/useFetchVehicles';
import { useOnTripSubmit } from '../../hooks/useOnTripSubmit';
import { tripFormSchema } from '../../schemas/TripFormSchema';
import { TripFormState } from '../../services/TripService';
import { formatDate } from '../../utilities/DateUtils';
import { useAuth } from '../AuthProvider/AuthProvider';
import { SuggestionInput } from '../SuggestionInput/SuggestionInput';
import { TripMap } from '../TripMap/TripMap';
import './create-trip-page.scss';

registerLocale('nb', nb);


export const CreateTripPage = () => {
  const { token, user } = useAuth();
  const { vehicles, vehicleError } = useFetchVehicles(user, token);
  const [currentStep, setCurrentStep] = useState(1);
  const [startPointSelected, setStartPointSelected] = useState(false);
  const [destinationSelected, setDestinationSelected] = useState(false);
  const [startPointCoordinates, setStartPointCoordinates] = useState<number[]>();
  const [destinationCoordinates, setDestinationCoordinates] = useState<number[]>();
  const [distance, setDistance] = useState<string>('');
  const [tollCost, setTollCost] = useState<number>();
  const [ferryCost, setFerryCost] = useState<number>();
  const methods = useForm<TripFormState>({
    resolver: yupResolver(tripFormSchema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    control,
    trigger,
  } = methods;

  const validateCurrentStep = async () => {
    switch (currentStep) {
      case 1:
        return await trigger(['startDate', 'startTime']);
      case 2:
        return await trigger(['startPoint', 'destination']);
      case 3:
        return await trigger(['vehicleNumber']);
      case 4:
        return await trigger(['maxCapacity']);
      case 5:
        return true;
      default:
        return false;
    }
  };
  const handleNextStep = async () => {
    const isValid = await validateCurrentStep();
    if (isValid) setCurrentStep(currentStep + 1);
  };

  const { onSubmit, addedTrip, createTripError } = useOnTripSubmit(vehicles, handleNextStep, reset, token, user);


  return (
    <div className="container-fremtur container-center">
      {createTripError && <div className="error-message">{createTripError}</div>}
      {vehicleError && <div className="error-message">{vehicleError}</div>}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="trip-form">
          {currentStep === 1 && (
            <div className="form-card">
              <h1 className="fremtur-heading">Når reiser du?</h1>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="startDate">Dato</label>
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        minDate={new Date()}
                        locale="nb"
                        formatWeekDay={(day: string) => day.substr(0, 3)}
                        onChange={(date: Date) => field.onChange(date)}
                        selected={field.value}
                        id="startDate"
                        placeholderText="Dato"
                        dateFormat="dd/MM/yyyy"
                        autoComplete="off"
                        customInput={
                          <input
                            placeholder="Dato"
                            id="startDate"
                            className="form-input form-input-date"
                          />}
                      />
                    )}
                  />
                  <p className="form-error">{errors.startDate?.message}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="startTime">Klokkeslett</label>
                  <Controller
                    name="startTime"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        locale="nb"
                        onChange={(date: Date) => field.onChange(date)}
                        selected={field.value}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        id="startTime"
                        placeholderText="Klokkeslett"
                        autoComplete="off"
                        customInput={
                          <input
                            placeholder="Klokkeslett"
                            className="form-input form-input-date"
                            id="startTime"
                          />}
                      />
                    )}
                  />
                  <p className="form-error">{errors.startTime?.message}</p>
                </div>
              </div>
              <div className="form-row">
                <button className="fremtur-btn" type="button" onClick={handleNextStep}>Gå videre</button>
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div className="form-card">
              <h1 className="fremtur-heading">Hvor går turen?</h1>
              <div className="form-row">
                <SuggestionInput
                  name="startPoint"
                  labelName="Fra"
                  label="Reiser fra"
                  hasSelected={startPointSelected}
                  setHasSelected={setStartPointSelected}
                  setCoordinates={setStartPointCoordinates}
                  trigger={trigger}
                />
                <SuggestionInput
                  name="destination"
                  labelName="Til"
                  label="Reiser til"
                  hasSelected={destinationSelected}
                  setHasSelected={setDestinationSelected}
                  setCoordinates={setDestinationCoordinates}
                  trigger={trigger}
                />
              </div>
              <TripMap
                startPointCoordinates={startPointCoordinates}
                destinationCoordinates={destinationCoordinates}
                distance={distance}
                ferryCost={ferryCost}
                tollCost={tollCost}
                setDistance={setDistance}
                setFerryCost={setFerryCost}
                setTollCost={setTollCost}
              />
              <div className="form-row">
                <button className="fremtur-btn" type="button" onClick={handleNextStep}>Gå videre</button>
              </div>
            </div>
          )}
          {currentStep === 3 && (
            <div className="form-card">
              <h1 className="fremtur-heading">Hvilket kjøretøy skal du bruke?</h1>
              <div className="form-row-single form-row">
                {vehicles.length === 0
                  ?
                  <div className="form-group">
                    <h3>
                      Finner ingen registrerte kjøretøy. Vennligst legg til et kjøretøy for turen.
                    </h3>
                    <label htmlFor="licensePlateNumber">
                      Registreringsnummer eller personlig skilt
                    </label>
                    <input
                      className="form-input"
                      type="text"
                      id="vehicleNumber"
                      placeholder="F.eks. AB-12345"
                      {...register('vehicleNumber')}
                    />
                    <p className="form-error">{errors.vehicleNumber?.message}</p>
                  </div>
                  : <div className="form-group">
                    <label htmlFor="vehicleNumber">Velg kjøretøy fra listen</label>
                    <select
                      className="form-select"
                      id="vehicleNumber"
                      {...register('vehicleNumber')}
                    >
                      {vehicles.map((vehicle) =>
                        <option key={vehicle.id} value={vehicle.id}>{vehicle.color} {vehicle.brand}</option>,
                      )}
                    </select>
                    <p className="form-error">{errors.vehicleNumber?.message}</p>
                  </div>
                }
              </div>
              <div className="form-row">
                <button className="fremtur-btn" type="button" onClick={handleNextStep}>Gå videre</button>
              </div>
            </div>
          )}
          {currentStep === 4 && (
            <div className="form-card">
              <h1 className="fremtur-heading">Hvor mange ledige plasser har du?</h1>
              <div className="form-row-single form-row">
                <div className="form-group-single form-group">
                  <div className="form-col">
                    <button className="fremtur-btn button-increment" type="button" onClick={() => {
                      const currentValue = parseInt(watch('maxCapacity').toString() || '1', 10);
                      if (currentValue > 1) setValue('maxCapacity', (currentValue - 1));
                    }}>-
                    </button>
                  </div>
                  <div className="form-col">
                    <label htmlFor="maxCapacity">Antall ledige plasser</label>
                    <input className="form-input" id="maxCapacity" type="number" {...register('maxCapacity')} min="1"
                           max="8" defaultValue="1" />
                    <p className="form-error">&nbsp;{errors.maxCapacity?.message}</p>
                  </div>
                  <div className="form-col">
                    <button className="fremtur-btn button-increment" type="button" onClick={() => {
                      const currentValue = parseInt(watch('maxCapacity').toString() || '0', 10);
                      if (currentValue < 8) setValue('maxCapacity', (currentValue + 1));
                    }}>+
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <button className="fremtur-btn" type="button" onClick={handleNextStep}>Gå videre</button>
              </div>
            </div>
          )}
          {currentStep === 5 && (
            <div className="form-card">
              <h1 className="fremtur-heading">Priskalkulator</h1>
              <div className="form-row-triple form-row">
                <div className="form-group">
                  <label htmlFor="distance">Avstand (km)</label>
                  <input
                    className="form-input"
                    id="distance"
                    type="number"
                    min="0"
                    defaultValue={parseInt(distance)}
                    {...register('distance')}
                  />
                  <p className="form-error">{errors.distance?.message}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="ferryCost">Ferjekostnader (kr)</label>
                  <input
                    className="form-input"
                    id="ferryCost"
                    type="number"
                    min="0"
                    defaultValue={ferryCost}
                    {...register('ferryCost')}
                  />
                  <p className="form-error">{errors.ferryCost?.message}</p>
                </div>
                <div className="form-group">
                  <label htmlFor="tolls">Bompenger (kr)</label>
                  <input
                    className="form-input"
                    id="tolls"
                    type="number"
                    min="0"
                    defaultValue={tollCost}
                    {...register('tolls')}
                  />
                  <p className="form-error">{errors.tolls?.message}</p>
                </div>
              </div>
              <div className="form-row">
                <button className="fremtur-btn" type="submit">Opprett reisen</button>
              </div>
            </div>
          )}
          {currentStep === 6 && addedTrip && (
            <div className="form-card">
              <div className="heading-nb">
                Reise fra {addedTrip.startPoint.name} til {addedTrip.destination.name} den{' '}
                {formatDate(addedTrip.startDate)} ble registrert
              </div>
              <Link className="fremtur-link" to="/min-side">
                <div className="form-row">
                  <button className="fremtur-btn">Tilbake til min side</button>
                </div>
              </Link>
            </div>
          )
          }
        </form>
      </FormProvider>
    </div>
  );
};
