import React, { useState } from 'react';
import { useRating } from '../../hooks/useRating';
import { Reservation } from '../../models/Reservation';
import { Trip } from '../../models/Trip';
import { isInPast } from '../../utilities/DateUtils';
import './rating.scss';

interface RatingProps {
  trip: Trip;
  reservation?: Reservation;
  showRating?: boolean;
}

export const Rating: React.FC<RatingProps> = ({ trip, reservation, showRating = true }) => {
    const ratings = [1, 2, 3, 4, 5];
    const [newRating, setNewRating] = useState(0);
    const { submitRating, rating, error } = useRating(newRating,trip, reservation);

    if (reservation && isInPast(reservation.trip.startDate) && rating === 0) {
      return (
        <div className="trip-rating">
          <div className="trip-rating-row">
            <div className="trip-stars-row">
              <div className="trip-driver">
                {trip.driver.name}
              </div>
              {ratings.map((star) => (
                <span
                  className={`trip-star ${newRating >= star ? 'marked' : 'unmarked'}`}
                  key={star}
                  onClick={() => setNewRating(star)}
                >
                ★
              </span>
              ))}
            </div>
            <button className="trip-rating-btn fremtur-btn small" onClick={submitRating}>Gi vurdering</button>
          </div>
          {error && <div className="error-message">{error}</div>}
        </div>
      );
    } else {
      return trip &&
        <div className="trip-rating">
          <div className="trip-rating-row">
            <div className="trip-stars-row">
              <div className="trip-driver">
                <i className="trip-driver-icon fa fa-user-circle-o" aria-hidden="true"></i>
                {trip.driver.name}
              </div>
            </div>
            {showRating &&
              <span className="trip-driver-rating">
              {rating !== 0
                ? `${rating}/5 ★`
                : 'Ingen vurdering'
              }
            </span>}
          </div>
        </div>;
    }
  }
;
