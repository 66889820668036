import React from 'react';
import { useForm } from 'react-hook-form';
import './chat-message-form.scss';

export interface ChatMessageFormValues {
  message: string;
}

interface ChatMessageFormProps {
  sendMessage: (messageData: ChatMessageFormValues) => void;
}

export const ChatMessageForm: React.FC<ChatMessageFormProps> = ({ sendMessage }) => {
  const { register, handleSubmit, reset } = useForm<ChatMessageFormValues>();

  const onSubmit = (data: ChatMessageFormValues) => {
    sendMessage(data);
    reset();
  };

  return (
    <form className="chat-message-form" onSubmit={handleSubmit(onSubmit)}>
      <input
        {...register('message', { required: true })}
        type="text"
        placeholder="Skriv en melding..."
        className="chat-message-input"
        autoComplete="off"
      />
      <button type="submit" className="send-button">
        <i className="send-icon fa fa-paper-plane" aria-hidden="true"></i>
      </button>
    </form>
  );
};
