import React, { useEffect, useState } from 'react';
import { genderOptionsFA } from '../../enums/Gender';
import { ReservationStatus, reservationStatusText } from '../../enums/ReservationStatus';
import { Reservation } from '../../models/Reservation';
import { Trip } from '../../models/Trip';
import { fetchReservationsOnTrip, updateReservationStatus } from '../../services/TripService';
import { formatDateToWeekday, formatTime } from '../../utilities/DateUtils';
import { useAuth } from '../AuthProvider/AuthProvider';
import { ApprovalModal } from '../Modals/ApprovalModal';
import './reservation-list.scss';

const renderReservation = (
  reservation: Reservation,
  openModal: (reservation: Reservation) => void,
  isModalOpen: boolean,
  closeModal: () => void,
  approveTrip: () => void,
  rejectTrip: () => void,
  openCard: number,
  index: number,
  toggleCard: (index: number) => void,
) => {
  const trip = reservation.trip;
  return <>
    <div className="trip-row">
      <div className="trip-date">{formatDateToWeekday(trip.startDate)}</div>
      <div className="reservation-status">
        {reservation.status === ReservationStatus.PENDING
          ? <button aria-label="approve" onClick={() => openModal(reservation)}
                    className={`reservation-status-floating ${reservation.status.toLowerCase()} clickable`}>
            {reservationStatusText[reservation.status]}
          </button>
          : <div className={`reservation-status-floating ${reservation.status.toLowerCase()}`}>
            {reservationStatusText[reservation.status]}
          </div>
        }
        <ApprovalModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onAccept={approveTrip}
          onReject={rejectTrip}
        />
      </div>
    </div>
    <div className="trip-row">
      <div className="trip-col">
        <div className="trip-time">{formatTime(trip.startTime)}</div>
      </div>
      <div className="trip-col">
        <div className="trip-timeline">
          <div className="circle"></div>
          <div className="line"></div>
          <div className="circle"></div>
        </div>
      </div>
      <div className="trip-col">
        <div className="trip-segment">
          <div className="trip-location">
            <div className="trip-location-name">{trip.startPoint.name}</div>
            <div className="trip-location-county">{trip.startPoint.county}</div>
            <>&nbsp;</>
          </div>
        </div>
        <div className="trip-segment">
          <div className="trip-location">
            <div className="trip-location-name">{trip.destination.name}</div>
            <div className="trip-location-county">{trip.destination.county}</div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-expand">
      <div className={`trip-expand ${openCard === index && 'expanded'}`}>
        <div className="trip-user-info-row">
          <div className="trip-user-name">
            <span className="user-info-label">Passasjer:</span>
            <span className="user-info-data">
            {reservation.user.name} <i className={`fa ${genderOptionsFA[reservation.user.gender]} user-info-icon`}></i>
          </span>
          </div>
          <div className="trip-info-rating">
            {<span className="trip-user-rating">
              {reservation.user.rating !== 0
                ? `${reservation.user.rating}/5 ★`
                : 'Ingen vurdering'
              }
            </span>}
          </div>
        </div>
      </div>
      <div className="trip-divider"></div>
      <div className="trip-details-row">
        <div className="trip-text">Totalpris for 1 passasjer:</div>
        <div className="trip-bold">Kr. {reservation.trip.reservationPrice},-</div>
      </div>
    </div>
    <div className="trip-row">
      <button aria-label="info" className="trip-expand-btn" onClick={() => toggleCard(index)}>
        <i className={`trip-arrow ${openCard === index ? 'up' : 'down'}`}></i>
      </button>
    </div>
  </>;
};

interface ReservationForApprovalListProps {
  trips: Trip[];
}

export const ReservationList: React.FC<ReservationForApprovalListProps> = ({ trips }) => {
  const { user, token } = useAuth();
  const [openCard, setOpenCard] = useState<number>(-1);
  const [error, setError] = useState<string>();
  const [reservation, setReservation] = useState<Reservation>();
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const loadReservationsOnTrip = async (tripId: string) => {
    try {
      const reservationData = await fetchReservationsOnTrip(tripId, token);
      setReservations(prev => [...prev, ...reservationData]);
      setError('');

    } catch (error) {
      setError('Klarte ikke å hente reservasjoner');
      console.error(error);
    }
  };

  useEffect(() => {
    setReservations([]);
    trips.forEach(trip => loadReservationsOnTrip(trip.id));
  }, [user]);

  const toggleCard = (index: number) => {
    openCard == index
      ? setOpenCard(-1)
      : setOpenCard(index);
  };

  const openModal = (reservation: Reservation): void => {
    setIsModalOpen(true);
    setReservation(reservation);
  };
  const closeModal = (): void => {
    setIsModalOpen(false);
    setReservation(undefined);
  };


  const rejectTrip = (): void => {
    const performRejectTrip = async () => {
      if (reservation) {
        try {
          await updateReservationStatus(ReservationStatus.REJECTED, reservation.id, reservation.trip.id, token);
          setReservations([]);
          trips.forEach(trip => loadReservationsOnTrip(trip.id));
          setError('');
        } catch (error) {
          setError('Klarte ikke å avslå turen');
          console.error(error);
        }
      }
    };
    performRejectTrip();
    closeModal();
  };

  const approveTrip = (): void => {
    const performApproveTrip = async () => {
      if (reservation) {
        try {
          await updateReservationStatus(ReservationStatus.APPROVED, reservation.id, reservation.trip.id, token);
          setReservations([]);
          trips.forEach(trip => loadReservationsOnTrip(trip.id));
          setError('');
        } catch (error) {
          setError('Klarte ikke å akseptere reisen');
          console.error(error);
        }
      }
    };
    performApproveTrip();
    closeModal();
  };

  return (
    <div className="trip-list">
      {error && <div className="container-center error-message">{error}</div>}
      {reservations
        && (reservations.length === 0
          ? <h3>Du har ingen reiser til godkjenning.</h3>
          : reservations.map((reservation: Reservation, index: number) => (
            <div
              key={index}
              className="trip-card"
            >
              {renderReservation(reservation, openModal, isModalOpen, closeModal, approveTrip, rejectTrip, openCard, index, toggleCard)}
            </div>
          )))}
    </div>
  );
};
