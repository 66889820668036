import { RatingType } from '../enums/RatingType';
import { DriverRatingRequest } from '../models/DriverRatingRequest';
import { Reservation } from '../models/Reservation';

interface RatingResponse {
  rating: number;
  note?: string;
}

export const fetchRating = async (
  token?: string,
  reservation?: Reservation,
): Promise<RatingResponse | null> => {
  if (!token) throw new Error('No access token found');
  if (!reservation) throw new Error('No reservation found');

  const endpoint = `/v1/ratings/driver?reservationId=${reservation.id}`;
  const response = await fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(
      `Failed to get rating: ${response.status} ${response.statusText}`,
    );
  }
  try {
    return await response.json();
  } catch (error) {
    return null;
  }
};

export const createRating = async (
  rating: number,
  token?: string,
  reservation?: Reservation,
): Promise<RatingResponse> => {
  if (!token) throw new Error('No access token found');
  if (!reservation) throw new Error('No reservation found');

  const driverRatingRequest: DriverRatingRequest = {
    rating: rating,
    ratingType: RatingType.DRIVER_RATING,
    reservationId: reservation.id,
  };

  const response = await fetch('/v1/ratings', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(driverRatingRequest),
  });

  if (!response.ok)
    throw new Error(
      `Failed to rate driver: ${response.status} ${response.statusText}`,
    );
  return response.json();
};
