import React from 'react';
import { genderOptions } from '../../enums/Gender';
import { User } from '../../models/User';
import './contact-info.scss';

export const ContactInfo = ({ user }: { user: User }) => (
  <div className="container-center">
    <h1 className="fremtur-heading">Kontaktinformasjon</h1>
    <div className="contact-info-card">
      <div className="contact-info-row">
        <i className="fa fa-user contact-info-icon"></i>
        <span className="contact-info-label">Navn:</span>
        <span className="contact-info-data">{user.name}</span>
      </div>
      <div className="contact-info-row">
        <i className="fa fa-person-half-dress contact-info-icon"></i>
        <span className="contact-info-label">Kjønn:</span>
        <span className="contact-info-data">{genderOptions[user.gender]}</span>
      </div>
      <div className="contact-info-row">
        <i className="fa fa-calendar contact-info-icon"></i>
        <span className="contact-info-label">Alder:</span>
        <span className="contact-info-data">{user.age} år</span>
      </div>
      <div className="contact-info-row">
        <i className="fa fa-phone contact-info-icon"></i>
        <span className="contact-info-label">Telefonnummer:</span>
        <span className="contact-info-data">{user.phoneNumber}</span>
      </div>
      <div className="contact-info-row">
        <i className="fa fa-envelope contact-info-icon"></i>
        <span className="contact-info-label">E-post:</span>
        <span className="contact-info-data">{user.email}</span>
      </div>
    </div>
  </div>
);
