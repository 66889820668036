import { DriverDetailsRequests, DriverDetailsUpdateRequest } from '../models/DriverDetailsRequests';
import { Reservation } from '../models/Reservation';
import { Trip } from '../models/Trip';
import { User } from '../models/User';
import { Vehicle } from '../models/Vehicle';
import { VehicleRequest, VehicleUpdateRequest } from '../models/VehicleRequest';

export const fetchUser = async (token?: string): Promise<User> => {
  if (!token) throw new Error('No access token found');

  const response = await fetch(`/v1/users/me`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok)
    throw new Error(
      `Failed to fetch user info: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const registerUserAsDriver = async (
  driverDetailsRequest: DriverDetailsRequests,
  token?: string,
  user?: User,
) => {
  if (!token) throw new Error('No access token found');
  if (!user) throw new Error('No user found');

  const response = await fetch(`/v1/users/${user.id}/driver`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(driverDetailsRequest),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to update user info: ${response.status} ${response.statusText}`,
    );
  }
};

export const updateUserAsDriver = async (
  driverDetailsUpdateRequest: Partial<DriverDetailsUpdateRequest>,
  token?: string,
  user?: User,
) => {
  if (!token) throw new Error('No access token found');
  if (!user) throw new Error('No user found');
  if (!user.driverDetails) throw new Error('User is not registered as driver');


  const response = await fetch(`/v1/users/${user.id}/driver`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(driverDetailsUpdateRequest),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to update user info: ${response.status} ${response.statusText}`,
    );
  }
};

export const fetchReservationsOnUser = async (
  token?: string,
  user?: User,
): Promise<Reservation[]> => {
  if (!token) throw new Error('No access token found');
  if (!user) throw new Error('No user found');

  const response = await fetch(`/v1/users/${user.id}/reservations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok)
    throw new Error(
      `Failed to fetch reservations: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const fetchTripsOnUser = async (
  token?: string,
  user?: User,
): Promise<Trip[]> => {
  if (!token) throw new Error('No token found');
  if (!user) throw new Error('No user found');

  const response = await fetch(`/v1/users/${user.id}/trips`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok)
    throw new Error(
      `Failed to fetch trips: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const registerVehicleOnUser = async (
  vehicleRequest: VehicleRequest,
  token?: string,
  user?: User,
) => {
  if (!token) throw new Error('No access token found');
  if (!user) throw new Error('No user found');

  const response = await fetch(`/v1/users/${user.id}/driver/vehicle`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(vehicleRequest),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to register vehicle: ${response.status} ${response.statusText}`,
    );
  }
};

export const fetchVehiclesOnUser = async (user?: User, token?: string): Promise<Vehicle[]> => {
  if (!token) throw new Error('No access token found');
  if (!user) throw new Error('No user found');

  const response = await fetch(`/v1/users/${user.id}/driver/vehicle`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok)
    throw new Error(
      `Failed to fetch vehicles: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const updateVehicleOnUser = async (
  updatedVehicle: Vehicle,
  token?: string,
  user?: User,
) => {
  if (!token) throw new Error('No access token found');
  if (!user) throw new Error('No user found');
  if (!user.driverDetails) throw new Error('User is not registered as driver');

  const vehicleUpdateRequest: VehicleUpdateRequest = {
    newBrand: updatedVehicle.brand,
    newColor: updatedVehicle.color,
  };

  const response = await fetch(`/v1/users/${user.id}/driver/vehicle/${updatedVehicle.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(vehicleUpdateRequest),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to update vehicle: ${response.status} ${response.statusText}`,
    );
  }
};

export const deleteVehicleOnUser = async (
  selectedVehicle: Vehicle,
  token?: string,
  user?: User,
) => {
  if (!token) throw new Error('No access token found');
  if (!user) throw new Error('No user found');
  if (!user.driverDetails) throw new Error('User is not registered as driver');

  const response = await fetch(`/v1/users/${user.id}/driver/vehicle/${selectedVehicle.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  });

  if (!response.ok) {
    throw new Error(
      `Failed to update vehicle: ${response.status} ${response.statusText}`,
    );
  }
};
